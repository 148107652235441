.Wrapper {
  font-family: $fontBagueSansPro;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.6rem;
  margin-top: 0;
  order: 3;
  text-align: center;

  em {
    font-style: italic;
  }

  :global(.catchquestion) {
    font-weight: 900;
    text-transform: uppercase;
  }

  @media (min-width: $smBreakpoint) {
    max-height: 333px;
    overflow-y: auto;
    padding-right: 8px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: color($white alpha(20%));
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: color($white alpha(60%));
      }
    }
  }
}

.Credit {
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.1rem;
}
