.teaser3x2 {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(1, auto);
    margin-bottom: 30px;

    .Item {
      margin-bottom: 30px;
    }
    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
    .Item3 { grid-area: 4 / 1 / 5 / 25; }
    .Item4 { grid-area: 5 / 1 / 6 / 25; }
    .Item5 { grid-area: 6 / 1 / 7 / 25; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-row-gap: 0;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(1, auto);
      margin-bottom: $margin40;

      .Item {
        margin-bottom: 40px;
      }

      .Item0 { 
        border-right: 1px solid $neutralC; 
        grid-area: 1 / 1 / 2 / 9;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item1 { 
        border-right: 1px solid $neutralC; 
        grid-area: 1 / 9 / 2 / 17;
        margin-left: 2px;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item2 { 
        grid-area: 1 / 17 / 2 / 25; 
        margin-left: 2px;
        padding-right: 1px;
      }

      .Item3 { 
        border-right: 1px solid $neutralC; 
        grid-area: 2 / 1 / 3 / 9;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item4 { 
        border-right: 1px solid $neutralC; 
        grid-area: 2 / 9 / 3 / 17;
        margin-left: 2px;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item5 { 
        grid-area: 2 / 17 / 3 / 25; 
        margin-left: 2px;
        padding-right: 1px;
      }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      grid-row-gap: 0;
      margin-bottom: 50px;

      .Item {
        margin-bottom: 50px;
      }

      .Item0 { 
        margin-right: -12px;
        padding-right: 13px;
      }

      .Item1 { 
        margin-left: 0;
        margin-right: -12px;
        padding-right: 12px;
      }

      .Item2 { 
        margin-left: 0;
      }

      .Item3 { 
        margin-right: -12px;
        padding-right: 12px;
      }

      .Item4 { 
        margin-left: 0;
        margin-right: -12px;
        padding-right: 12px;
      }

      .Item5 { 
        margin-left: 0;
      }
    }
  }
}
