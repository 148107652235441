.SelectIcon {
  color: $neutralB;
  float: right;
  font-size: 1.8rem;
  letter-spacing: -0.9px;
  margin-top: 0;
  pointer-events: none;
  position: relative;
  right: 6px;
  top: -26px;

  @media (min-width: $xsBreakpoint) {
    /* >= 320 */
    position: absolute;
    top: 6px;
  }

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    position: relative;
    top: -4px;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    top: -36px;
  }
}

.Form {
  position: relative;
}

.Black {
  color: $neutralA;
}

.Blank {
  margin-bottom: 39px;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin-bottom: 25px;
  }
}

.InputWrapper {
  margin-bottom: 30px;
  margin-left: 5px;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin-bottom: 0;
    margin-left: 34px;
  }
}

.InputWrapperLined {
  margin-bottom: 8px;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    display: flex;
    margin: 0 0 25px;
  }
}

.Input {
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 20px;
  margin-top: 0;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 2rem;
    line-height: 3;
    margin-bottom: 0;
    text-align: left;
  }
}

.InputDefault {
  color: $neutralA;
}

.InputInvalid {
  color: $error;
}

.InputWithSpaceAround {
  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin: 0 25px;
  }
}

.SelectFieldWrapper {
  margin-bottom: 22px;
  margin-top: 50px;
  position: relative;

  @media (min-width: $xsBreakpoint) {
    /* >= 320 */
    margin-bottom: 0;
    margin-top: 34px;
  }

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin: 18px 0 0;
    min-width: 100px;
    position: static;
  }
}

.Select {
  appearance: menulist;
}

.Instructions {
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.4rem;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.7rem;
    line-height: 2.2rem;
    padding-left: 34px;
    text-align: left;
  }

  @media (min-width: $smBreakpoint) {
    /* >= 960 */
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}

.SuccessMessage {
  composes: TextGoldGradient from 'gmHelpers.legacy.css';
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 3px;
  line-height: 1.5rem;
  margin-bottom: 39px;
  margin-top: 36px;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin-top: 28px;
    padding-left: 34px;
    text-align: left;
  }
}

.ErrorMessage {
  composes: SuccessMessage;
  background: $error;
}

.ButtonWrapper {
  margin: 0 -21px;
  position: relative;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin: 0 0 34px 38px;
  }
}

.ActionLink {
  background: $gradientPrimaryA;
  border: 0;
  border-radius: 0;
  color: $white;
  cursor: pointer;
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  font-weight: 900;
  height: 42px;
  letter-spacing: 3px;
  line-height: 1.2rem;
  padding-right: 0;
  position: relative;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin-left: -3px; /* remove letter spacing on the right side to get a perfect edge */
    width: 241px;
  }

  &:focus {
    outline: 0;
  }

  &::before {
    color: $white;
    content: '\e909';
    font-family: $fontIcon;
    font-size: 45px;
    font-weight: 100;
    left: 0;
    line-height: 45px;
    margin-left: -115px;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: -3px;

    @media (min-width: $smBreakpoint) {
      /* >= 760 */
      font-size: 40px;
      left: 40px;
      line-height: 40px;
      margin-left: 0;
      right: auto;
      top: 0;
    }
  }
}

.Gray {
  background: $neutralD;
  cursor: not-allowed;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px $white inset;
}
