.Wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ShortTitle {
  composes: ShortTitleAGradientCaminada from 'typography.legacy.css';
  margin-bottom: $margin4;
  margin-top: $margin16;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin12;
  }
}

.ShortTitleBold {
  font-weight: 900;
}

.Title {
  composes: Title from '../ArticleLeadBlog/styles.legacy.css';
}

.Lead {
  composes: Lead from '../ArticleLeadBlog/styles.legacy.css';
}

.DateWrapper {
  composes: DateWrapper from '../ArticleLeadBlog/styles.legacy.css';
}

.Link {
  &:active,
  &:visited,
  &:hover {
    @mixin ShortTitleABoldGradientCaminada;
  }
}
