/* 
  THIS CSS IS DONE FOR DEBUGING DEESIGNS BY GRID 
  THIS IS WHY WE DID'NT ADD THE COLLORS OR ANY OTHER VALUESS IN
  GLOBAL DEFINITIONS
*/
.Wrapper {
  background-color: white; /* stylelint-disable-line */
  height: 100vh;
  inset: 0;
  opacity: 0.2;
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.Container {
  composes: Container from '../../assets/styles/grid.legacy.css';
  background-color: turquoise; /* stylelint-disable-line */
}

.Column {
  background-color: lightblue; /* stylelint-disable-line */
  border-left: 1px dotted gray; /* stylelint-disable-line */

  &:first-of-type {
    border: 0;
  }
}

.Item {
  background-color: fuchsia;  /* stylelint-disable-line */
  height: 100vh;
  width: 100%;
}

