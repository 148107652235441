.DividerThick {
  composes: DividerThick from 'Keywords/styles.legacy.css';
  margin-bottom: 20px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 10px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: -1px;
    margin-top: 1px;
  }
}

.Lead {
  display: block;
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 20px;
  text-align: -webkit-center;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin: 0 auto 30px;
    max-width: 640px;
  }
}

.Header {
  color: $neutralA;
  font-family: $fontActaHeadline;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-family: $fontActaDisplay;
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 3.5rem;
    margin-top: 88px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-top: 88px;
  }
}

.HeaderTitle {
  font-family: $fontActaDisplay;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.1rem;
  margin: 58px auto 20px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 3.5rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 3.5rem;
    margin-bottom: 15px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    font-size: 4rem;
    line-height: 4rem;
  }
}
