.OverlayWrapper {
  display: none;
  font-size: 3.3rem;
  letter-spacing: 0.3px;
  position: relative;

  &.Visible {
    display: block;
  }
}
