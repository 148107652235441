.Wrapper {
  background: $neutralG;
  font-family: $fontBagueSansPro;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 5px 0;
}

.ListWrapper {
  display: block;
  text-align: right;
}

.ListItem,
.CloseButtonWrapper {
  color: $neutralA;
  display: inline-block;
  list-style: none;

  & + .ListItem:not(:last-of-type)::before {
    content: '|';
  }
}

.CloseButtonWrapper {
  background: $transparent;

  .CloseButton {
    appearance: none;
    background: $transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      color: $neutralD;
    }

    > i {
      line-height: 0;
    }
  }
}

.Link {
  color: $neutralA;
  display: inline-block;
  padding: 0 5px;

  &:hover {
    text-decoration: underline;
  }
}
