.DividerThick {
  background-color: $neutralA;
  clear: both;
  height: 10px;
  margin-bottom: 15px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 4px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: -4px;
    margin-top: 8px;
  }
}

.Lead {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 18px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 33px;
  }

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 28px;
  }
}

.HeaderTitle {
  font-family: $fontActaDisplay;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin: 20px auto;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 6.8rem;
    line-height: 12rem;
    margin-bottom: 2px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    font-size: 10rem;
    line-height: 12rem;
    margin-bottom: 20px;
  }
}
