.ExpansionPanel {
  position: relative;
  width: 100%;

  .Header,
  .HeaderCentered {
    align-items: center;
    background: $white;
    color: $neutralA;
    cursor: pointer;
    display: flex;
    outline: none;
    padding: 12px 0;
    position: relative;
    transition: transform 0.25s ease-in-out;
    user-select: none;
    width: 100%;

    &::after {
      background-color: $neutralB;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s;
      width: 100%;
    }

    @media (min-width: $mdBreakpoint) {
      padding: 17px 0;
    }
  }

  .HeaderCentered {
    justify-content: center;
  }

  .Icon {
    &::before {
      color: $neutralA;
      display: inline-block;
      font-size: 3rem;
      transition: color 0.3s ease-in-out;
    }

    &.ArrowIcon {
      line-height: 1;
      transform: rotate(0deg);
      transform-origin: 50% 35%;
      transition: transform 0.3s ease-in-out;

      &::before {
        background-image: url('graphics/Ic_dropdown_default.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        height: 12px;
        width: 20px;
      }
    }
  }

  .Content {
    background-color: $white;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  &.IsOpen {
    .Header {
      &::after {
        opacity: 1;
      }
    }

    .ArrowIcon {
      transform: rotate(180deg);
    }
  }
}

.HeaderContentWrapper {
  align-items: center;
  display: flex;
}

.Title,
.BoldTitle {
  transition: color 0.3s;
}

.Title {
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2.5px;
  line-height: 1.3rem;
  margin-right: 10px;
  text-transform: uppercase;

  @media (min-width: $mdBreakpoint) {
    font-size: 1.2rem;
    letter-spacing: 4px;
    line-height: 1.4rem;
  }
}

.BoldTitle {
  font-family: $fontBagueSansPro;
  font-size: 1.4rem;
  font-weight: 900;
  letter-spacing: 2.01px;
  line-height: 1.8rem;
}
