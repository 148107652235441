.Title {
  composes: H5 from 'typography_old.legacy.css';
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 1;
}

.GroupTitle {
  composes: H6 from 'typography_old.legacy.css';
  font-weight: bold;
  line-height: 1.5;
}

.Link {
  color: $zueriA;
  display: block;
  font-family: $fontBagueSansPro;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;

  &:visited {
    color: $zueriA;
  }
}
