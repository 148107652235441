.landscape {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(18, auto);

    .Item {
      margin-bottom: 30px;
    }
    .Item0 { grid-area: 1 / 1 / 2 / 25; }
    .Item1 { grid-area: 2 / 1 / 3 / 25; }
    .Item2 { grid-area: 3 / 1 / 4 / 25; }
    .Item3 { grid-area: 4 / 1 / 5 / 25; }
    .Item4 { grid-area: 5 / 1 / 6 / 25; }
    .Item5 { grid-area: 6 / 1 / 7 / 25; }
    .Item6 { grid-area: 7 / 1 / 8 / 25; }
    .Item7 { grid-area: 8 / 1 / 9 / 25; }
    .Item8 { grid-area: 9 / 1 / 10 / 25; }
    .Item9 { grid-area: 10 / 1 / 11 / 25; }
    .Item10 { grid-area: 11 / 1 / 12 / 25; }
    .Item11 { grid-area: 12 / 1 / 13 / 25; }
    .Item12 { grid-area: 13 / 1 / 14 / 25; }
    .Item13 { grid-area: 14 / 1 / 15 / 25; }
    .Item14 { grid-area: 15 / 1 / 16 / 25; }
    .Item15 { grid-area: 16 / 1 / 17 / 25; }
    .Item16 { grid-area: 17 / 1 / 18 / 25; }
    .Item17 { grid-area: 18 / 1 / 19 / 25; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(6, auto);

      .Item {
        margin-bottom: $margin40;
      }
      .Item0 { grid-area: 1 / 1 / 2 / 13; }
      .Item1 { grid-area: 1 / 13 / 2 / 25; }
      .Item2 { grid-area: 2 / 1 / 3 / 7; }
      .Item3 { grid-area: 2 / 7 / 3 / 13; }
      .Item4 { grid-area: 2 / 13 / 3 / 19; }
      .Item5 { grid-area: 2 / 19 / 3 / 25; }
      .Item6 { grid-area: 3 / 1 / 4 / 13; }
      .Item7 { grid-area: 3 / 13 / 4 / 25; }
      .Item8 { grid-area: 4 / 1 / 5 / 7; }
      .Item9 { grid-area: 4 / 7 / 5 / 13; }
      .Item10 { grid-area: 4 / 13 / 5 / 19; }
      .Item11 { grid-area: 4 / 19 / 5 / 25; }
      .Item12 { grid-area: 5 / 1 / 6 / 13; }
      .Item13 { grid-area: 5 / 13 / 6 / 25; }
      .Item14 { grid-area: 6 / 1 / 7 / 7; }
      .Item15 { grid-area: 6 / 7 / 7 / 13; }
      .Item16 { grid-area: 6 / 13 / 7 / 19; }
      .Item17 { grid-area: 6 / 19 / 7 / 25; }
    }
  }

  /* Desktop */
  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;

      .Item {
        margin-bottom: 50px;
      }
    }
  }
}
