.EmptyResultWrapper {
  color: $neutralA;
  float: none;
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  line-height: 1.7em;
  margin-bottom: 8rem;
  margin-top: 20px;
  text-align: left;
}

.EmptyResultTitle {
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 25px;
}

.EmptyResultSubTitle {
  font-weight: 600;
}

.EmptyResultList {
  list-style: initial;
  margin-left: 1.5rem;
}

.EmptyResultListEntry {
  margin: 1rem 0;
}
