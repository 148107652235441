.KeywordWrapper {
  line-height: 0;
  margin-bottom: -30px; /* 1/2 of max image size */
  text-align: center;
  transform: translateY(-50%); /* pull icons on the hero image */
  width: 100%;
  z-index: 2;

  @media (min-width: $smBreakpoint) {
    margin-bottom: -43px; /* 1/2 of max image size */
  }
}

.KeywordLink {
  display: inline-block;
}

.KeywordDisruptor {
  height: 60px;
  width: auto;

  @media (min-width: $smBreakpoint) {
    height: 86px;
  }
}
