.Wrapper {
  margin: 40px 0;

  @media (min-width: $xsBreakpoint) {
    margin: 80px 0;
  }
}

.Title {
  composes: MainTitle from 'typography_old.legacy.css';
  line-height: normal;
}

.Description {
  composes: Subtitle from 'typography_old.legacy.css';

  margin-top: 16px;
  text-align: left;

  @media (min-width: $xsBreakpoint) {
    margin-top: 8px;
  }
}

.Icon {
  height: 72px;
  margin-bottom: 37px;
  max-height: 72px;
}
