.TitleChar {
  display: inline-block;
  font-family: $fontActaDisplay;
  font-size: 10rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 10px;
  margin-left: $mediumMargin;
  margin-top: -10px;

  @media (min-width: $smBreakpoint) {
    /* > 760 */
    float: left;
    font-size: 16rem;
    margin-left: auto;
    margin-top: 0;
  }

  @media (min-width: $mdBreakpoint) {
    /* > 960 */
    font-size: 20rem;
    margin-top: -32px;
  }
}

.FooterLinkWrapper {
  text-align: center;

  .OverviewLink {
    display: inline-block;
    float: none;
    margin-bottom: 40px;
    margin-top: 40px;

    @media (min-width: $smBreakpoint) {
      /* > 760 */
      font-size: 1.3rem;
      line-height: 1.6rem;
      padding-left: 25px;
    }

    @media (min-width: $mdBreakpoint) {
      /* > 960 */
      font-size: 1.5rem;
      line-height: 1.9rem;
      padding-left: 30px;
    }
  }
}

.OverviewLink {
  color: $neutralA;
  cursor: pointer;
  float: right;
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  font-weight: 900;
  height: 1.5rem;
  letter-spacing: 3px;
  line-height: 1rem;
  margin-bottom: 9px;
  margin-top: 1px;
  padding-left: 15px;
  position: relative;
  text-transform: uppercase;

  &::before {
    color: $neutralA;
    content: '\e909';
    display: inline-block;
    font-family: $fontIcon;
    font-size: 2.2rem;
    font-weight: 100;
    left: -10px;
    line-height: 1;
    padding: 0;
    position: absolute;
    -webkit-text-fill-color: $neutralA; /* stylelint-disable-line property-no-vendor-prefix */
    text-transform: none;
    top: -8px;

    @media (min-width: $smBreakpoint) {
      font-size: 4.5rem;
      left: -20px;
      top: -19px;
    }
  }
}

.Header {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  text-align: center;
}

.Divider {
  background-color: $neutralA;
  clear: both;
  height: 1px;
  margin-bottom: 15px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 17px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: 25px;
  }
}

.CharlistWrapper {
  margin-top: 1px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-top: 1px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-top: 10px;
  }
}

@media (min-width: $smBreakpoint) and (max-width: $smBreakpointTo) {
  /* 760 - 959 */
  .HiddenOnTabletOnly {
    display: none;
  }
}

@media (min-width: $smBreakpoint) {
  /* > 760 */
  .HiddenOnTablet {
    display: none;
  }
}

.NoKeywordsTextWrapper {
  font-size: 1.5rem;
  padding-left: 103px;
  padding-top: 22px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* > 760 */
    font-size: 1.7rem;
    min-height: 150px;
    padding-left: 32px;
    padding-top: 60px;
  }

  @media (min-width: $mdBreakpoint) {
    /* > 960 */
    font-size: 2rem;
    min-height: 230px;
    padding-left: 150px;
    padding-top: 86px;
    text-align: center;
  }
}
