.Wrapper {
  margin-bottom: 40px;
  overflow: hidden; /* to hide not visible part of sky ad */

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: 60px;
  }
}

.Header {
  background: $gradientPrimaryA;
  color: $white;
  float: left;
  font-family: $fontBagueSansPro;
  position: relative;
  text-align: center;
  width: 100%;

  &::after {
    background-color: $neutralA;
    bottom: 0;
    content: '';
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.Lead {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 28px;
  padding: 0 10px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 43px;
    padding: 0 58px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: 48px;
  }
}

.HeaderTitle {
  font-family: $fontActaDisplay;
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin: 15px auto;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 6rem;
    line-height: 12rem;
    margin: 3px auto;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    font-size: 8rem;
    line-height: 8rem;
    margin: 15px auto 19px;
  }
}

.SearchForm {
  margin-top: 10px;

  input {
    margin-bottom: 7px;
  }

  button {
    margin-bottom: 20px;
  }

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-top: 8px;

    input {
      margin-bottom: 30px;
    }
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-top: 14px;

    input {
      margin-bottom: 38px;
    }
  }
}
