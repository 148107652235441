.Section {
  width: 100%;
}

.SectionPullOut {
  composes: Section;
  margin: 0;

  @media (min-width: $smBreakpoint) {
    margin: 0 -$pullOutSm;
    width: calc(100% + ($pullOutSm * 2));
  }

  @media (min-width: $mdBreakpoint) {
    margin: 0 -$pullOutMd;
    width: calc(100% + ($pullOutMd * 2));
  }

  @media (min-width: $xlBreakpoint) {
    margin: 0 -$pullOutXl;
    width: calc(100% + ($pullOutXl * 2));
  }

  @media print {
    margin: 0 auto;
    width: 100%;
  }
}
