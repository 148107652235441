.Wrapper {
  margin-bottom: 40px;

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: 60px;
  }
}

.Header {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  text-align: center;
}

.DividerThick {
  background-color: $neutralA;
  clear: both;
  height: 10px;
  margin-bottom: 20px;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 30px;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    margin-bottom: 40px;
  }
}

.Lead {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 18px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 28px;
  }

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 36px;
  }
}

.HeaderTitle {
  font-family: $fontActaDisplay;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin: 20px auto;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 6.8rem;
    line-height: 12rem;
    margin: 3px auto 0;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    font-size: 10rem;
    line-height: 12rem;
    margin: 14px auto 20px;
  }
}
