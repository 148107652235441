.LinkWrapper {
  color: $neutralA;
  display: inline-block;
  padding: 10px 0;
  width: 100%;

  &:hover {
    .Button {
      background-color: $primaryA;
    }
  }

  &.IsActive {
    opacity: 0.4;
  }
}

.TeaserText {
  float: left;
  padding-left: 15px;
  width: 50%;

  @media (min-width: $smBreakpoint) {
    float: inherit;
    padding: 21px 8px 0;
    text-align: center;
    width: 100%;
  }

  &:hover {
    .Button {
      background-color: $primaryA;
    }
  }
}

.TeaserShortTitle {
  background-color: $transparent;
  display: block;
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.2rem;
  text-transform: uppercase;
  word-wrap: break-word;
}

.TeaserTitle {
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.9rem;
  word-wrap: break-word;

  @media (min-width: $mdBreakpoint) {
    font-size: 2rem;
    line-height: 2.1rem;
    margin-top: 4px;
  }
}

.Button {
  background-color: $neutralA50;
  border-radius: 50%;
  height: 34px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 34px;

  &::after {
    border: solid 8px transparent;
    border-left: solid 12px $white;
    color: $white;
    content: '';
    position: absolute;
    right: 2px;
    top: 9px;
  }
}

.PlayerWrapper {
  float: left;
  position: relative;
  width: 50%;

  @media (min-width: $smBreakpoint) {
    float: inherit;
    width: 100%;
  }
}

.IsActive {
  color: $neutralD;
}

.ActiveButton {
  background-color: $neutralD;
}

.Image {
  background-position: 50%;
  background-size: cover;
  display: block;
  height: 100%;
  min-height: 80px;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  z-index: 0;
}
