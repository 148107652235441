.Clearfix {
  *zoom: 1;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.FloatRight {
  float: right;
}

.FloatLeft {
  float: left;
}

.Unselectable {
  user-select: none;
}

.TextEllipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ASPECT RATIOS */
.AspectRatio {
  position: relative;

  &::before {
    content: ' ';
    display: block;
  }
}

.AspectRatioContent {
  inset: 0;
  position: absolute;
}

.AspectRatio1x1 {
  composes: AspectRatio;

  &::before {
    padding-top: 100%;
  }

  @media print {
    padding-top: initial;
  }
}

.AspectRatio3x2 {
  composes: AspectRatio;

  &::before {
    padding-top: 66.6667%;

    @media print {
      padding-top: initial;
    }
  }
}

.AspectRatio16x9 {
  composes: AspectRatio;

  &::before {
    padding-top: 56.25%;

    @media print {
      padding-top: initial;
    }
  }
}

.AdContainer {
  height: 100%;
  text-align: left;
  text-transform: uppercase;

  :global(.gmadhead) {
    font-size: 10px;
    font-weight: 300;
    height: 12px;
    letter-spacing: 1px;
    margin: 0 auto;
    text-align: left;

    /* lg: >= 760; container=700px, 24 columns, col-width=11.166666667px, gutter-width=20px */
    @media (min-width: $smBreakpoint) {
      /* >= 760 */
      width: calc(100% - $outerGapMd); /* 700px */
    }

    /* xl: >= 960; container=900px, 24 columns, col-width=12.5px, gutter-width=25px */
    @media (min-width: $mdBreakpoint) {
      width: calc(960px - $outerGapXl);
    }
  }

  :global(.gmadhead_mob) {
    font-size: 10px;
    font-weight: 300;
    height: 12px;
    letter-spacing: 1px;
    margin: 0 auto;
    text-align: left;

    /* xs & sm: default; container=279px, 24 columns, col-width=0.625px, gutter-width=11px */
    width: calc(100% - $outerGapXs); /* 0 - 479 */
  }
}

.TextButton {
  /* Corrects inability to style clickable `input` types in iOS */
  appearance: none;

  background: transparent;
  border: none;

  /* inherit font & color from ancestor */
  color: $inherit;
  font: inherit;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
