.Wrapper {
  margin: 36px 0 $margin16;
}

.Title {
  composes: HeadingCNeutralA from 'typography.legacy.css';
  margin-bottom: 8px;
}

.Description {
  composes: FormLabelANeutralA from 'typography.legacy.css'; 
  margin-bottom: 36px;
}
