/* BUTTONS */
.InputButton {
  background: none;
  border: none;
  color: $neutralA;
  cursor: pointer;
  display: flex;
  font: 1rem/1.8rem $fontBagueSansPro;
  letter-spacing: 2px;
  outline: none;
  padding: 0 0 0 36px;
  position: relative;
  text-transform: uppercase;

  &::before {
    background: currentColor;
    content: '';
    height: 18px;
    left: 8px;
    position: absolute;
    top: 0;
    transform: rotateZ(32deg);
    width: 1px;
  }

  @media (min-width: $smBreakpoint) {
    /* > 760 */
    font-size: 1.3rem;
    letter-spacing: 4px;
    line-height: 3.6rem;
    padding-left: 32px;

    &::before {
      height: 34px;
      left: 10px;
    }
  }

  @media (min-width: $mdBreakpoint) {
    /* > 960 */
    font-size: 1.5rem;
    letter-spacing: 5px;
  }
}

.InputButtonBold {
  composes: InputButton;
  font-weight: 900;
}

.UnstyledButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;

  @media print {
    display: none;
  }
}

/* INPUT TEXT */
.InputText {
  background-color: $transparent;
  border: none;
  border-bottom: 2px solid $neutralB;
  box-shadow: none;
  color: $neutralA;
  font-family: $fontActaHeadline;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 14px;
  outline: none;
  padding: 0 0 8px;
  width: 100%;
}

.InputSearch {
  composes: InputText;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }
}
