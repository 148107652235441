.Wrapper {
  margin-bottom: 40px;
  overflow-x: hidden;

  @media (min-width: $mdBreakpoint) {
    /* > 960 */
    margin-bottom: 60px;
  }
}

.Header {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  text-align: center;
}

.OverviewLink {
  composes: TextButton from 'helpers.legacy.css';
  color: $neutralA;
  cursor: pointer;
  float: right;
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  font-weight: 900;
  height: 1.5rem;
  letter-spacing: 2px;
  line-height: 1rem;
  margin-bottom: 9px;
  margin-top: 1px;
  padding-left: 15px;
  position: relative;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    /* > 760 */
    display: none;
  }

  &::after {
    background: $gradientPrimaryA;
  }

  &::before {
    color: $neutralA;
    content: '\e909';
    display: inline-block;
    font-family: $fontIcon;
    font-size: 2.2rem;
    left: -10px;
    line-height: 1;
    padding: 0;
    position: absolute;
    -webkit-text-fill-color: $neutralA; /* stylelint-disable-line property-no-vendor-prefix */
    text-transform: none;
    top: -6px;

    @media (min-width: $smBreakpoint) {
      font-size: 4.5rem;
      font-weight: 100;
      left: -20px;
      top: -19px;
    }
  }
}

.OverviewLinkActive {
  composes: TextGoldGradient from 'gmHelpers.legacy.css';
  -webkit-text-fill-color: $neutralA;

  &::before {
    background: $gradientPrimaryA;
    /* stylelint-disable */
    background-clip: text !important;
    content: '\e909';
    display: inline-block;
    font-family: $fontIcon;
    font-size: 2.2rem;
    font-weight: 100;
    left: -10px;
    line-height: 1;
    padding: 0;
    position: absolute;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable */
    text-transform: none;
    top: -6px;

    @media (min-width: $smBreakpoint) {
      font-size: 4.5rem;
      left: -20px;
      top: -19px;
    }
  }
}

.Divider {
  background-color: $neutralA;
  clear: both;
  height: 1px;

  @media (min-width: $smBreakpoint) {
    display: none;
  }
}

.DividerMarginBottom {
  composes: Divider;
  margin-bottom: 29px;

  @media (min-width: $smBreakpoint) {
    display: block;
    margin-bottom: 31px;
  }

  @media (min-width: $smBreakpoint) {
    margin-bottom: 41px;
  }
}
