.Wrapper {
  composes: Section from 'sections.legacy.css';
  position: relative;
  will-change: transition;
  z-index: 0;
}

.FooterHeader {
  width: 100%;
}

.MenuSection {
  border-bottom: 1px solid $neutralC;
}

.LogoWrapper {
  display: flex;
  justify-content: center;
  margin: $margin64 0 $margin32;

  @media (min-width: $smBreakpoint) {
    justify-content: flex-start;
  }
}

.SocialMediaBarWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: $margin32;

  @media (min-width: $smBreakpoint) {
    justify-content: flex-end;
    margin: $margin64 0 0;
  }
}

.ListItem {
  margin-bottom: $margin12;
}

.Link {
  composes: InfoBNeutralA from 'typography.legacy.css';
  overflow-wrap: break-word;
  word-break: break-word;

  &:hover {
    @mixin InfoBPrimaryB;
  }
}

.Disclaimer {
  composes: InfoBBoldNeutralA from 'typography.legacy.css';
}

.CollapseToggleWrapper {
  @media (min-width: $smBreakpoint) {
    text-align: right;
  }
}

.PublicationCollapseHeader {
  padding: 16px 0;

  @media (min-width: $smBreakpoint) {
    align-items: center;
    display: flex;
    height: 87px;
    padding: 0;
  }
}

.PublicationCollapseHeaderCol {
  line-height: 1;
  padding-bottom: 8px;
  padding-top: 8px;
}

.PublicationCollapseBody {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  visibility: hidden;
}

.PublicationCollapseBodyIsOpen {
  max-height: 1000px;
  visibility: visible;
}

.PublicationList {
  columns: 1;
  list-style: none;
  margin-bottom: $margin32;

  @media (min-width: $smBreakpoint) {
    columns: 2;
  }

  @media (min-width: $mdBreakpoint) {
    columns: 4;
    margin-bottom: 270px;
  }
}

.PublicationToggle {
  composes: InfoBNeutralA from 'typography.legacy.css';
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;

  &:hover {
    color: $primaryB;
    cursor: pointer;
  }
}

.PublicationToggleIsOpen {
  align-items: center;
  display: flex;

  @media (min-width: $smBreakpoint) {
    justify-content: flex-end;
  }
}

.ExpansionIcon {
  font-size: 18px;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.ExpansionIconOpen {
  transform: rotate(180deg);
}
