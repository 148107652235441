/* Make sure to change Grid0 */
.teaserStageRandomizedList {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(1, 1fr);
    
    .Item {
      margin-bottom: 30px;
    }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(1, auto);

      .Item {
        margin-bottom: $margin40;
      }
      
      .Item0 { grid-area: 1 / 1 / 2 / 2; }
      .Item1 { grid-area: 1 / 2 / 2 / 3; }
      .Item2 { grid-area: 1 / 3 / 2 / 4; }
      .Item3 { grid-area: 1 / 4 / 2 / 5; }
    }
  }

  /* Desktop */
  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;
      
      .Item {
        margin-bottom: 50px;
      }
    }
  }
}
