.teaserStage2Items {
  /* Mobile */

  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);

    .Item {
      margin-bottom: 30px;
    }
    .Item0 { grid-area: 1 / 1 / 2 / 2; }
    .Item1 { grid-area: 2 / 1 / 3 / 2; }
    .Item2 { grid-area: 3 / 1 / 4 / 2; }
    .Item3 { grid-area: 4 / 1 / 5 / 2; }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;

      .Item0 { 
        border-right: 1px solid $neutralC; 
        grid-area: 1 / 1 / 2 / 2;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item1 { 
        grid-area: 1 / 2 / 2 / 3; 
        margin-left: 2px;
      }

      .Item2 { 
        border-right: 1px solid $neutralC; 
        grid-area: 2 / 1 / 3 / 2;
        margin-right: -10px;
        padding-right: 10px;
      }

      .Item3 { 
        grid-area: 2 / 2 / 3 / 3; 
        margin-left: 2px;
      }
    }
  }

  /* Desktop */
  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;

      .Item0 { 
        margin-right: -12px;
        padding-right: 12px;
      }

      .Item1 { 
        margin-left: 0;
      }

      .Item2 { 
        margin-right: -12px;
        padding-right: 12px;
      }

      .Item3 { 
        margin-left: 0;
      }
    }
  }
}
