.Wrapper {
  border: 1px solid $neutralA;
  margin-top: 50px;
  position: relative;
}

.HeaderWrapper {
  font-family: $fontBagueSansPro;
  font-weight: normal;
  overflow: hidden;
  padding: 14px 21px 0;

  @media (min-width: $smBreakpoint) {
    /* >= 760  */
    padding: 18px 0 0 21px;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960  */
    padding: 21px 21px 0;
  }
}

.HeaderText {
  font-family: $fontActaHeadline;
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 2.7rem;
  text-align: center;

  @media (min-width: $xsBreakpoint) {
    /* >= 320  */
    line-height: 2.4rem;
  }

  @media (min-width: $smBreakpoint) {
    /* >= 760  */
    font-family: $fontActaDisplay;
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 3.3rem;
    padding-left: 14px;
    text-align: left;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960  */
    font-size: 4rem;
    line-height: 4rem;
  }
}

.LeadText {
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-top: 18px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 1.7rem;
    line-height: 2.2rem;
    margin-top: 11px;
    padding: 0 12px 0 13px;
    text-align: left;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960  */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-top: 20px;
  }
}

.FormWrapper {
  padding: 0;
}

.TeaserImage {
  bottom: 0;
  max-height: 210px;
  padding-right: 10px;
  position: absolute;

  @media (min-width: $smBreakpoint) {
    /* >= 760  */
    max-height: 280px;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960  */
    max-height: 320px;
  }

  @media (min-width: $xlBreakpoint) {
    /* >= 1200  */
    max-height: 330px;
  }
}

.InnerContainer {
  composes: Container from '@grid.legacy.css';
  @media (min-width: $smBreakpoint) {
    width: auto;
  }
}
