.Wrapper {
  display: flex;
  gap: $margin12;
  line-height: 1;
  z-index: $zIndexHighest;

  @media (min-width: $smBreakpoint) {
    background-color: $transparent;
    position: relative;
    text-align: left;
  }
}

.WrapperOverlay {
  display: block;
  height: auto;
  margin-bottom: -$margin24;

  @media (min-width: $smBreakpoint) {
    margin-bottom: -$margin12;
  }
}
