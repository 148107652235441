.Wrapper {
  color: $neutralB;

  a {
    color: $neutralA;
    text-decoration: underline;
  }

  @media (min-width: $xlBreakpoint) {
    margin: 0 48px;
  }
}

.Title {
  composes: H1 from 'typography_old.legacy.css';
  font-family: $fontActaDisplay, serif;
  font-size: 8rem;
  font-weight: 900;
  line-height: 1.14;
  margin-bottom: 0;
  margin-top: 81px;
  text-align: center;

  @media (min-width: $xsBreakpointTo) {
    font-size: 12.8rem;
    line-height: 1.05;
    margin: 149px auto 0;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 160px;
    line-height: 192px;
    margin: 71px auto 0;
    width: 495px;
  }
}

.MainTitle {
  composes: H2 from 'typography_old.legacy.css';
  line-height: 30px;
  margin: 46px auto 0;
  text-align: center;
  width: 253px;

  @media (min-width: $xsBreakpointTo) {
    height: 60px;
    margin: 15px auto 0;
  }

  @media (min-width: $mdBreakpoint) {
    margin: 18px auto 0;
    width: 350px;
  }
}

.Msg {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  margin: 16px auto 60px;
  text-align: center;
  width: 245px;

  @media (min-width: $xsBreakpointTo) {
    margin: 19px auto 60px;
    text-align: center;
    width: 350px;
  }
}
