.BreadcrumbList {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0 auto 29px;
  overflow-x: auto;
  padding-top: 7px;
  white-space: nowrap;
  width: 100%;
}

.BreadcrumbLink {
  color: $greyB;
  display: inline-block;
  font-family: $fontBagueSansPro;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;  

  &::after {
    content: '›'; /* <-- do not replace with greater than sign */
    padding-left: 10px;
    padding-right: 10px;
  }
}

span.BreadcrumbLink {
  margin-right: 25px;

  &::after {
    content: '';
    padding-left: 0;
    padding-right: 0;
  }
}
