.Wrapper {
  composes: Clearfix from 'helpers.legacy.css';
  margin: auto;
  margin-bottom: 2.5rem;
}

.Title {
  composes: TeaserTitleBNeutralA from 'typography.legacy.css';
  margin-bottom: $margin8;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin16;
  }
}
