.Description {
  composes: CaptionAuthorParagraph from 'typography_old.legacy.css';
  margin-bottom: 12px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: $margin4;
  }
}

.ButtonWrapper {
  margin: 30px 0 22px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    margin: 30px 0 35px;
  }
}

.ArrowButton {
  color: $white;
}

.ErrorPanelWrapper {
  border: 1px solid $error;
  margin-bottom: 22px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 35px;
  }
}

.ErrorPanelHeader {
  composes: HeadingCatch3 from 'typography_old.legacy.css';
  align-items: center;
  background: $gradientPrimaryA;
  color: $white;
  display: flex;
  height: 77px;
  justify-content: center;
}

.ErrorPanelContent {
  composes: Paragraph from 'typography_old.legacy.css';
  align-items: center;
  color: $error;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 21px 30px;
  position: relative;
  text-align: center;
}

.ErrorIcon {
  color: $error;
  font-size: 7.5rem;
  margin: 12px 0;
}

.SuccessWrapper {
  background: $gradientPrimaryA;
  display: flex;
  margin-bottom: 22px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 35px;
  }
}

.SuccessTitle {
  composes: HeadingCatch3 from 'typography_old.legacy.css';
  margin-bottom: 10px;

  @media (min-width: $smBreakpoint) {
    margin-bottom: 5px;
  }
}

.SuccessContent {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 12px;

  @media (min-width: $smBreakpoint) {
    padding: 20px 30px 20px 0;
  }
}

.SuccessMessage {
  composes: Paragraph from 'typography_old.legacy.css';
}

.SuccessIcon {
  display: none;

  @media (min-width: $smBreakpoint) {
    display: block;
    font-size: 6rem;
    padding: 15px 20px 15px 15px;
  }
}

.RestrictionFormWrapper {
  text-align: center;
}

.ClosedContainer {
  align-items: center;
  border-bottom: 1px solid $neutralD;
  border-top: 1px solid $neutralD;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin32;
  margin-top: $margin32;
  padding: 16px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: $margin64;
    margin-top: $margin64;
  }
}

.ClosedIcon {
  font-size: 3.5rem;
  margin-bottom: $margin8;

  @media (min-width: $mdBreakpoint) {
    font-size: 4rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5rem;
  }
}

.ClosedMessage {
  composes: Heading3A from 'typography_old.legacy.css';
  text-align: center;
}

.RichTextWrapper {
  composes: RichTextWrapper from '../TextParagraph/styles.legacy.css';

  @media print {
    text-align: justify;
  }
}
