.ExpansionPanel .ShowMoreButton {
  color: $blackC;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ExpansionPanel {
  background-color: $greyE;
  border-radius: 8px;
  color: $blackC;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin24;
  padding: $margin12 $margin16;
  position: relative;
  width: 100%;
  
  &:hover {
    color: $blackA;
  }
}

.Content {
  background-color: $transparent;
  overflow: hidden;
  position: relative;
}

.Spacer {
  position: relative;
}

.ShowMoreButton {
  composes: ButtonTertiarySmall from 'typography.legacy.css';
  align-items: center;
  background: $transparent;
  color: $blackC;
  display: flex;
  font-weight: 700;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: $blackA;
  }
}
