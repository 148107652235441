.Image {
  height: auto;
  max-width: 100%;
  position: relative;
  width: auto;
}

.Landscape {
  height: auto;
  width: 100%;
}

.ImageBox {
  background-position: 50% 30%;
  background-size: cover;
  height: 100%;
  width: 100%;
}
