strong {
  font-weight: 500;
}

p {
  margin-top: 0;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: $fontActaHeadline;
  font-size: $bodyFontSize;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: $fontBagueSansPro;
}

h1,
h2 {
  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-family: $fontActaDisplay;
  }
}

.H1,
.H2,
.H3,
.H4 {
  color: $neutralA;
  font-family: $fontActaHeadline;
  margin-bottom: 2rem;
  margin-top: 0;
}

.H1 {
  /* 0 - 759 */
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.1rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-family: $fontActaDisplay;
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 3.5rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* 960 - infinity */
    font-size: 4rem;
    line-height: 4rem;
  }
}

.H2 {
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.1rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-family: $fontActaDisplay;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2.4rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* 960 - infinity */
    font-size: 3rem;
    line-height: 3rem;
  }
}

.H2White {
  composes: H2;
  color: $white;
}

.H3 {
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.1rem;
}

.H4 {
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.7rem;
    line-height: 2.6rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* 960 - infinity */
    font-size: 1.9rem;
    line-height: 1.9rem;
  }
}

.MainTitle {
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.12rem;
  margin-bottom: 0;

  @media (min-width: $smBreakpoint) {
    font-size: 3.6rem;
    line-height: 1.06rem;
  }

  @media (min-width: $smBreakpoint) {
    font-size: 4.3rem;
    left: 0;
    line-height: 1.09rem;
  }

  @media (min-width: $xlBreakpoint) {
    font-size: 5.5rem;
  }
}

.MainTitleBig {
  font-family: $fontActaDisplay;
  font-size: 5.7rem;
  font-weight: 900;
  letter-spacing: 1.2px;
  line-height: 5.7rem;
  margin-top: 23px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 6.8rem;
    line-height: 6.8rem;
    margin-top: 30px;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 10rem;
    line-height: 12rem;
    margin-top: 20px;
  }
}

.SubTitle {
  composes: H1;
  margin: 6px 0;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-bottom: 12px;
    margin-top: 24px;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 12px;
    margin-top: 9px;
  }
}

.Lead {
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-top: 15px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-top: 11px;
  }
}

.BodyText {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    font-size: 1.7rem;
    line-height: 2.6rem;
  }

  @media (min-width: $mdBreakpoint) {
    font-size: 1.9rem;
    line-height: 3.2rem;
  }
}

.Catch {
  color: $neutralA;
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 2rem;
  text-transform: uppercase;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.4rem;
    line-height: 2.6rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 1.5rem;
    line-height: 3.2rem;
  }
}

.SmallTitle {
  composes: Catch;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3rem;
  margin: 10px 0 0;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    margin-top: 18px;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-top: 23px;
  }
}

.Small {
  font-family: $fontBagueSansPro;
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 15px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    margin-top: 20px;
  }
}

.Link {
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;

  &:active,
  &:visited,
  &:hover {
    background: $gradientPrimaryA;

    /* stylelint-disable */
    background-clip: text;
    color: $primaryA;
    cursor: pointer;
    -webkit-text-fill-color: $transparent;
    /* stylelint-enable */
  }

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.7rem;
    line-height: 3.2rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 1.9rem;
  }
}

.ActiveLink {
  background: $gradientPrimaryA;
  background-clip: text;
  color: $primaryA;
  cursor: pointer;
  -webkit-text-fill-color: $transparent; /* stylelint-disable-line property-no-vendor-prefix */
}

.Markup {
  /* no link */
  color: $neutralA;
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.7rem;
    line-height: 3.2rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 1.9rem;
  }
}

.Blockquote {
  font-family: $fontBagueSansPro;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 300;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 2rem;
    line-height: 2.8rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.Caption {
  font-family: $fontBagueSansPro;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.Recipes {
  font-family: $fontBagueSansPro;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2rem;

  @media (min-width: $smBreakpoint) {
    /* >= 760 */
    font-size: 1.7rem;
    line-height: 2.6rem;
  }

  @media (min-width: $mdBreakpoint) {
    /* >= 960 */
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.ListStyle {
  ul {
    font-family: $fontBagueSansPro;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2rem;
    margin-left: 0;

    @media (min-width: $smBreakpoint) {
      /* >= 760 */
      font-size: 1.7rem;
      line-height: 2.6rem;
    }

    @media (min-width: $mdBreakpoint) {
      /* >= 960 */
      font-size: 1.9rem;
      line-height: 3.2rem;
    }

    li {
      list-style: none;
      margin-bottom: 20px;

      @media (min-width: $smBreakpoint) {
        /* >= 760 */
        margin-bottom: 30px;
      }

      @media (min-width: $mdBreakpoint) {
        /* >= 960 */
        margin-bottom: 40px;
      }

      &::before {
        background-color: $neutralA;
        border-radius: 50%;
        content: ' ';
        display: inline-block;
        height: 5px;
        line-height: 2rem;
        margin-right: 5px;
        padding-right: 3px;
        position: relative;
        top: -1px;
        width: 5px;

        @media (min-width: $smBreakpoint) {
          /* >= 760 */
          line-height: 2.6rem;
          padding-right: 4px;
          top: -3px;
        }

        @media (min-width: $mdBreakpoint) {
          /* >= 960 */
          font-size: 1.5rem;
          height: 6px;
          left: -1px;
          line-height: 3.2rem;
          padding-right: 5px;
          top: -2px;
          width: 6px;
        }
      }
    }
  }

  ol {
    counter-reset: section;
    font-family: $fontBagueSansPro;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2rem;
    margin-left: 0;

    @media (min-width: $smBreakpoint) {
      /* >= 760 */
      font-size: 1.7rem;
      line-height: 2.6rem;
    }

    @media (min-width: $mdBreakpoint) {
      /* >= 960 */
      font-size: 1.9rem;
      line-height: 3.2rem;
    }

    li {
      list-style: none;
      margin-bottom: 20px;

      @media (min-width: $smBreakpoint) {
        /* >= 760 */
        margin-bottom: 30px;
      }

      @media (min-width: $mdBreakpoint) {
        /* >= 960 */
        margin-bottom: 40px;
      }

      &::before {
        color: $neutralA;
        content: counter(section) '.';
        counter-increment: section;
        font-size: 1.2rem;
        font-weight: 900;
        line-height: 2rem;
        padding-right: 3px;
        position: relative;
        top: 0;

        @media (min-width: $smBreakpoint) {
          /* >= 760 */
          font-size: 1.4rem;
          line-height: 2.6rem;
          padding-right: 4px;
        }

        @media (min-width: $mdBreakpoint) {
          /* >= 960 */
          font-size: 1.5rem;
          line-height: 3.2rem;
          padding-right: 5px;
        }
      }
    }
  }
}
