.Wrapper {
  margin-bottom: 40px;

  @media (min-width: $mdBreakpoint) {
    margin-bottom: 60px;
  }
}

.Header {
  composes: Header from 'Restaurants/styles.legacy.css';
  background: none;
  color: $neutralA;
  font-family: $fontBagueSansPro;
  text-align: center;

  &::after {
    @media (min-width: $smBreakpoint) {
      left: calc(25px / 2);
      max-width: calc(100% - 25px);
    }

    @media (min-width: $mdBreakpoint) {
      left: calc(25px / 2);
      max-width: calc(100% - 25px);
    }
  }
}

.EmptyResultTitle {
  font-family: $fontActaDisplay;
  font-size: 2.4rem;
  letter-spacing: 0.27px;
  line-height: 4rem;
}

.FilterItemsList {
  padding: 10px 0 50px;
}

.FilterItem {
  /* stylelint-disable */
  background: $neutralA;
  background-clip: text !important;
  display: block;
  font-family: $fontActaDisplay;
  font-size: 2.4rem;
  letter-spacing: 0.27px;
  line-height: 4rem;
  -webkit-text-fill-color: transparent;
  /* stylelint-enable */

  &.Active,
  &:hover {
    background: $gradientPrimaryA;

    &::after {
      background: $gradientPrimaryA;
    }
  }
}

.Lead {
  display: block;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.7rem;
  margin-bottom: 18px;
  text-align: center;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 28px;
  }

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    margin-bottom: 36px;
  }
}

.HeaderTitle {
  font-family: $fontActaDisplay;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin: 20px auto;

  @media (min-width: $smBreakpoint) {
    /* width >= 760 */
    font-size: 6.8rem;
    line-height: 12rem;
    margin: 3px auto 0;
  }

  @media (min-width: $mdBreakpoint) {
    /* width >= 960 */
    font-size: 10rem;
    line-height: 12rem;
    margin: 14px auto 20px;
  }
}

.TeaserListWrapper {
  margin-top: 2px;
}
