.teaserBookmark4x4 {
  /* Mobile */
  .Grid0 {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(17, auto);

    .Item {
      margin-bottom: 30px;
    }

    @for $index from 0 to 16 {
      .Item$(index) {
        grid-area: calc($index + 1) / 1 / calc($index + 2) / 25;
      }
    }
  }

  /* Tablet */
  @media (min-width: $smBreakpoint) {
    .Grid0 {
      display: grid;
      grid-column-gap: $gridGutterWidthSm;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(4, auto);

      .Item {
        margin-bottom: $margin40;
      }
      .Item0 { grid-area: 1 / 1 / 2 / 7; }
      .Item1 { grid-area: 1 / 7 / 2 / 13; }
      .Item2 { grid-area: 1 / 13 / 2 / 19; }
      .Item3 { grid-area: 1 / 19 / 2 / 25; }
      .Item4 { grid-area: 2 / 1 / 3 / 7; }
      .Item5 { grid-area: 2 / 7 / 3 / 13; }
      .Item6 { grid-area: 2 / 13 / 3 / 19; }
      .Item7 { grid-area: 2 / 19 / 3 / 25; }
      .Item8 { grid-area: 3 / 1 / 4 / 7; }
      .Item9 { grid-area: 3 / 7 / 4 / 13; }
      .Item10 { grid-area: 3 / 13 / 4 / 19; }
      .Item11 { grid-area: 3 / 19 / 4 / 25; }
      .Item12 { grid-area: 4 / 1 / 5 / 7; }
      .Item13 { grid-area: 4 / 7 / 5 / 13; }
      .Item14 { grid-area: 4 / 13 / 5 / 19; }
      .Item15 { grid-area: 4 / 19 / 5 / 25; }
      .Item16 { grid-area: 5 / 1 / 6 / 7; }
    }
  }

  @media (min-width: $xlBreakpoint) {
    .Grid0 {
      grid-column-gap: $gridGutterWidthXl;

      .Item {
        margin-bottom: 50px;
      }
    }
  }
}
